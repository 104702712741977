@tailwind base;
@tailwind components;

/* Emotion styles have to be SSRed! */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong,
b {
  font-weight: 700;
}

body {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

@media print {
  body {
    background-color: #fff;
  }
}
/* Emotion styles end */

/* Tailwind: Apply preflight manually */
.twbtn {
  all: unset;
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.smooth-scroll {
  scroll-behavior: smooth;
}

/* Fix anchor position https://stackoverflow.com/a/11842865 */
.postanchors h1,
.postanchors h2,
.postanchors h3,
.postanchors h4,
.postanchors h5,
.postanchors h6 {
  padding-top: 65px;
  margin-top: -65px;
}

body {
  overflow-x: hidden;
}

@media (max-width: 992px) {
  .containerInvisibleOnMobile {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }
  .cardContainer {
    padding: 0;
    margin: 0;
  }
}

/* Bottom navigation */
@media only screen and (max-width: 991px) {
  body {
    padding-bottom: 55px;
  }
}

@media only screen and (max-width: 991px) {
  .safebottom {
    padding-bottom: env(safe-area-inset-bottom);
  }
  .bottomnav {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/* post editor */
#editorjs-container .codex-editor__redactor {
  padding-bottom: 300px !important;
}

.highlightLinks a {
  color: #00897b !important;
}
.highlightLinks a:hover {
  color: #00695c !important;
  text-decoration: underline !important;
}
.underlineLinksOnHover a:hover {
  text-decoration: underline !important;
}

/* Dark mode fix */
.ce-conversion-tool,
.ce-inline-toolbar__buttons {
  color: black;
}

/* comment editor */
.codex-editor__redactor {
  padding-bottom: 100px !important;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}
.mapboxgl-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}

/* Editor inline menu */
.duDjGM {
  padding-bottom: 14px !important;
}
/* Editor textfield overwrites */
.lafGOK {
  background: none !important;
  color: inherit !important;
}

/* Bring editor notification to front */
.cdx-notifies {
  z-index: 9999 !important;
}

/* offset the navbar */
.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

.lazyImage img {
  object-fit: cover;
}

.fullheight {
  position: absolute;
  /* https://stackoverflow.com/questions/15021573/get-div-to-take-up-100-body-height-minus-fixed-height-header-and-footer */
  height: -moz-calc(100% - 65px); /* Firefox */
  height: -webkit-calc(100% - 65px); /* Chrome, Safari */
  height: calc(100% - 65px); /* IE9+ and future browsers */
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-mutedlight {
  color: #9e9e9e;
}

.react-images__positioner {
  z-index: 9999 !important;
}

body {
  word-wrap: break-word;
}

a,
a:hover {
  text-decoration: none !important;
  color: inherit;
}

.ablue {
  color: #009688 !important;
}

.hoverline:hover {
  text-decoration: underline !important;
  color: #009688;
}
.postcontent details {
  @apply tw-bg-primary-50 dark:tw-bg-primary-900 tw-p-5 tw-mb-10 tw-text-gray-900 dark:tw-text-gray-100;
}
.postcontent details summary {
  @apply tw-font-semibold;
}
.postcontent .lazy {
  width: 100%;
}
.postcontent .loaded {
  width: auto !important;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.postcontent table {
  width: 100%;
}
.postcontent table figure {
  margin-left: 0px;
  margin-right: 0px;
}

#editorjs-container,
.custom-html-style,
.custom-html-style p,
.rc-md-editor .editor-container .sec-md .input {
  font-size: 1.15rem !important;
  font-weight: 400;
}

/* Editor js */
#editorjs-container img,
#editorjs-container iframe,
.html-wrap iframe, /* Markdown editor */
.editorjs-container img {
  margin-left: auto;
  margin-right: auto;
}

/* Markdown editor */
.custom-html-style .videoWrapper .fullWidthIframe {
  height: 250px !important;
}

.postcontent,
.custom-html-style ol,
.custom-html-style ul {
  font-size: 1.15rem !important;
  font-weight: 400;
  line-height: 1.46429em;
}

.postcontent h1,
.postcontent h2,
.postcontent h3,
.postcontent h4,
.postcontent h5,
.postcontent h6 {
  padding-top: 0;
  font-weight: 600;
}
.postCardContent h1,
.postCardContent h2,
.postCardContent h3,
.postCardContent h4,
.postCardContent h5,
.postCardContent h6 {
  padding-top: 90px;
  padding-bottom: 10px;
}
.postcontent a {
  color: #009688 !important;
}
.postcontent p a,
.postcontent ul:not(.toc-list):not(.toc-list-l2):not(.toc-list-l3) li a,
.postcontent ol li a {
  border-bottom: 2px solid #4db6ac;
  /* text-decoration: underline !important; */
  /* text-decoration-color: #00897b !important; */
  background-size: 100% 200%;
  background-image: linear-gradient(to top, #4db6ac 50%, transparent 50%);
  transition: background-position 0.2s;
}
.postcontent a:hover {
  color: #1f1f1f !important;
  text-decoration: none !important;
  background-position: 0 100%;
}

.postcontent h1 {
  font-size: 2.2rem;
  line-height: 1.20588em;
}
.postcontent h2 {
  font-size: 2rem;
  line-height: 1.13333em;
  margin-left: -0.02em;
}
.postcontent h3 {
  font-size: 1.8rem;
  line-height: 1.30357em;
  margin-left: -0.02em;
  letter-spacing: -0.02em;
}
.postcontent h4 {
  font-size: 1.6rem;
  line-height: 1.33;
  letter-spacing: 0em;
}
.postcontent h5 {
  font-size: 1.35rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.postcontent h6 {
  font-size: 1.15rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.postcontent pre code {
  white-space: pre-line;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  width: 100%;
  word-spacing: 100%;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}
.postcontent blockquote {
  font-family: medium-content-slab-serif-font, Georgia, Cambria, Times, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.3rem;
  line-height: 1.48;
  letter-spacing: -0.012em;
  padding: 0 50px;
  quotes: '\201c''\201d';
}
.postcontent blockquote {
  padding: 17px 50px 1px 50px;
}
.postcontent blockquote:before {
  margin-left: -55px;
  position: absolute;
  height: 400px;
  color: #00897b;
  margin-top: 25px;
  float: left;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.postcontent hr {
  border: 0 none;
  margin-top: -15px;
  padding-bottom: 40px;
  text-align: center;
}
.postcontent hr:before {
  color: #00897b;
  display: inline-block;
  content: '***';
  font-size: 30px;
  line-height: 65px;
  height: 30px;
  letter-spacing: 0.2em;
}
.postcontent table {
  display: table;
  border: 1px;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: grey;
}
.postcontent td,
.postcontent th {
  padding: 0.5rem 0.625rem 0.625rem;
}
.postcontent th {
  @apply tw-bg-primary-300 dark:tw-bg-primary-600;
}
.postcontent tr {
  @apply tw-bg-primary-50 dark:tw-bg-primary-900;
}
.postcontent tr:nth-child(even) {
  @apply tw-bg-primary-100 dark:tw-bg-primary-800;
}

blockquote > center {
  margin: 0 -50px 0 -50px !important;
}
a .username {
  color: black;
}
.cpointer {
  cursor: pointer;
}
button:focus,
button:active,
button:hover,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
}
/* editor */
.mce-content-body {
  outline: 0px !important;
  -webkit-appearance: none;
}
.posteditor {
  min-height: 350px;
}
.inputtitle {
  font-size: 40px !important;
}

/* Media queries */
/* No media query for `xs` since this is the default in Bootstrap */
/* postCardContent paddings */
.postCardContent {
  padding: 0px 20px 20px 20px;
  overflow: hidden;
}
.postCardContent figure {
  padding: 20px 0;
  margin-left: -20px;
  margin-right: -20px;
}

figcaption,
.imgcaption,
div > figure + br + br + sup,
div > figure + br + sup,
div > figure + sup,
center sup {
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  padding-top: 5px;
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  font-weight: 400;
}
figcaption::after,
.imgcaption::after,
div > figure + br + br + sup::after,
div > figure + br + sup::after,
div > figure + sup::after {
  padding-top: 4px;
  content: ''; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  width: 40px; /* Change this to whatever width you want. */
  border-bottom: 3px solid #4db6ac; /* This creates the border. Replace black with whatever color you want. */
}
.postCardContent .fullWidthIframe {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: 40px;
  padding: 20px 0;
}
.fullwidth,
.postCardContent table {
  width: calc(100% + 40px) !important;
  margin-left: -20px;
  margin-right: -20px;
}
.fullwidth {
  padding: 20px 0;
}
/* End paddings */

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .pull-right {
    position: relative;
    width: 50%;
    float: right;
    padding: 5px 0 5px 20px;
  }
  .pull-left {
    position: relative;
    max-width: 50%;
    float: left;
    padding: 5px 20px 5px 0;
  }
  .pull-left figure,
  .pull-right figure {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  /* postCardContent paddings */
  .postCardContent {
    padding: 0px 50px 20px 50px;
  }
  .postCardContent figure {
    margin-left: -50px;
    margin-right: -50px;
  }
  figcaption {
    margin-left: 35px;
    margin-right: 35px;
  }
  .postCardContent .fullWidthIframe {
    width: calc(100% + 100px);
    margin-left: -50px;
  }
  .fullwidth,
  .postCardContent table {
    width: calc(100% + 100px) !important;
    margin-left: -50px;
    margin-right: -50px;
  }
  /* End paddings */
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .postCardContent .fullWidthIframe {
    max-height: 600px;
  }
  .container {
    max-width: 1240px;
  }
  /* postCardContent paddings */
  .postCardContent {
    padding: 20px 90px 20px 90px;
  }
  .postCardContent figure {
    margin-left: -90px;
    margin-right: -90px;
  }
  .postCardContent .fullWidthIframe {
    width: calc(100% + 180px);
    margin-left: -90px;
  }
  .fullwidth,
  .postCardContent table {
    width: calc(100% + 180px) !important;
    margin-left: -90px;
    margin-right: -90px;
  }
  /* End paddings */
}
@media only screen and (max-width: 992px) {
  .postCardContent .fullWidthIframe {
    max-height: 400px;
  }
  .container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .postCardContent .fullWidthIframe {
    max-height: 250px;
  }
}
.instagram-media {
  max-height: 1000px !important;
}

/* react-multi-carousel start */
.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}
.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .react-multi-carousel-track {
    overflow: visible !important;
  }
}

/* react-multi-carousel end */

/* Fix Safari bug: https://nextjs.org/docs/api-reference/next/future/image */
@media not all and (min-resolution: 0.001dpcm) {
  img[loading='lazy'] {
    clip-path: inset(0.5px);
  }
}

@tailwind utilities;
